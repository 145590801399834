import request from "@/utils/request";
import Cookies from "js-cookie";

//获取员工信息
export function staffInfo(params) {
  return request({
    url: `/staff/info/${params}`,
    method: "GET",
  });
}

//新增反馈
export function feedbackPost(data) {
  return request({
    url: `/staff/feedback`,
    method: "post",
    data
  });
}

// 处理反馈意见
export function feedbackPut(data) {
  return request({
    url: `/staff/feedback`,
    method: "put",
    data
  });
}

// 分页获取反馈列表
export function getPageList(params) {
  return request({
    url: `/staff/feedback/getPageList`,
    method: "get",
    params
  });
}

// 获取意见反馈详情
export function feedbackInfo(params) {
  return request({
    url: `/staff/feedback/info/${params.code}`,
    method: "get",
    params
  });
}


// 获取所有员工列表
export function getAllStaffList(params) {
  return request({
    url: `/staff/getAllStaffList`,
    method: "get",
    params
  });
}

// 获取个人中心信息
export function getMyStatffInfo(params) {
  return request({
    url: `/staff/getMyStatffInfo`,
    method: "get",
    params
  });
}