import request from "@/utils/request";

//获取今日打卡详情
export function clockInfo(params) {
  return request({
    url: "/staff/clock/info",
    method: "get",
    params: params,
  });
}

//打卡
export function handleClock(params) {
  return request({
    url: "/staff/clock/handleClock",
    method: "get",
    params: params,
  });
}

// 获取日历
export function getCalnndars(params) {
  return request({
    url: "/staff/clock/getCalnndars",
    method: "get",
    params: params,
  });
}
// 按月份统计出勤情况
export function statisticsByMonth(params) {
  return request({
    url: "/staff/clock/statisticsByMonth",
    method: "get",
    params: params,
  });
}

// 获取服务器时间
export function getServiceTime(params) {
  return request({
    url: "/staff/clock/getServiceTime",
    method: "get",
    params: params,
  });
}

// 获取打卡规则
export function getClockRule(params) {
  return request({
    url: "/staff/clock/getClockRule",
    method: "get",
    params: params,
  });
}