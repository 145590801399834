

<template>
  <div class="by-day">
    <div class="loading-box" v-if="mainloading">
      <van-loading />
    </div>
    <div class="search">
      <div class="left">
        <div class="year">{{ year }}</div>
        <div class="split"></div>
        <div class="month">{{ month }}</div>
        <div class="month">.</div>
        <div class="month">{{ day }}</div>
      </div>
      <div class="back-today" @click="backToday">回到今天</div>
    </div>
    <div :style="`height:${expand ? 'auto' : '1.2rem'}`" class="swipe-box">
      <van-swipe
        ref="swipe"
        :show-indicators="false"
        :key="swipeKey"
        class="my-swipe"
        id="mySwipe"
        @change="swipeRoll"
      >
        <van-swipe-item v-for="(item, index) in calnndarList" :key="index">
          <ul class="calendar" ref="swipeItem">
            <li class="day-of-week">一</li>
            <li class="day-of-week">二</li>
            <li class="day-of-week">三</li>
            <li class="day-of-week">四</li>
            <li class="day-of-week">五</li>
            <li class="day-of-week">六</li>
            <li class="day-of-week">日</li>
            <li
              :class="{
                'date-item': true,
                checked: isChecked(subItem), //选择
                today: today == subItem.date, //当天
                restDay: 1 == subItem.isRestDay, //非工作日
                notMonthType: 1 != subItem.monthType, //非当月
                abnormalClock: 0 == subItem.clockType, //异常打卡 或 外勤打卡
                normalClock: 1 == subItem.clockType, //正常打卡
              }"
              v-for="(subItem, subIndex) in item"
              :key="'day' + index + subIndex"
              @click="handleCheck(subItem)"
            >
              <div class="date">{{ subItem.day }}</div>
              <div class="point"></div>
            </li>
          </ul>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="split">
      <van-icon
        name="arrow-up"
        v-if="expand"
        class="icon"
        @click="expand = !expand"
      />
      <van-icon
        name="arrow-down"
        v-if="!expand"
        class="icon"
        @click="expand = !expand"
      />
    </div>
    <div class="bottom">
      <p class="row">出勤统计：工时{{ checkItem.totalHours || 0 }}小时</p>
      <ul class="clock-in-ul" v-if="2 != checkItem.clockType">
        <li class="clock-in-li">
          <div class="left">
            <div class="point"></div>
            <div class="line"></div>
          </div>
          <div class="right">
            <div class="row1">
              上班
              {{ checkItemClock.onWork.clockTime | formatTime }}
              <span v-if="2 == checkItemClock.onWork.clockStatus" class="tag"
                >迟到</span
              >
              <span v-if="!checkItemClock.onWork.clockStatus" class="tag"
                >缺卡</span
              >
              <span v-if="4 == checkItemClock.onWork.clockScope" class="tag"
                >外勤</span
              >
            </div>
            <div class="row2" v-if="checkItemClock.onWork.address">
              <van-icon name="location-o" class="icon" />{{
                checkItemClock.onWork.address
              }}
            </div>
          </div>
        </li>

        <li
          class="clock-in-li"
          v-if="
            !(today == this.checkItem.date && !checkItemClock.offWork.clockTime)
          "
        >
          <div class="left">
            <div class="point"></div>
            <div class="line"></div>
          </div>
          <div class="right">
            <div class="row1">
              下班
              {{ checkItemClock.offWork.clockTime | formatTime }}
              <!-- 1-正常2-迟到3-早退 -->
              <span v-if="3 == checkItemClock.offWork.clockStatus" class="tag"
                >早退</span
              >
              <span v-if="!checkItemClock.offWork.clockStatus" class="tag"
                >缺卡</span
              >
              <span v-if="4 == checkItemClock.offWork.clockScope" class="tag"
                >外勤</span
              >
            </div>
            <div class="row2" v-if="checkItemClock.offWork.address">
              <van-icon name="location-o" class="icon" />{{
                checkItemClock.offWork.address
              }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getCalnndars, getServiceTime } from "@/api/clock/index.js";

export default {
  name: "byDay",
  props: {},
  data() {
    return {
      calnndarList: [],
      year: "",
      month: "",
      day: "",
      swipeKey: 0,
      loading: false,
      today: "",
      checkItem: {
        list: [],
      },
      expand: true,
      mainloading: true,
    };
  },
  watch: {},
  filters: {
    formatType(val) {
      const rules = {
        0: "上班",
        1: "下班",
      };
      return rules[val];
    },
    formatTime(val) {
      if (!val) {
        return "未打卡";
      }
      return val.substring(11);
    },
  },
  async mounted() {
    await this.getTime();
    await this.getClockByDay();
    this.$nextTick(() => {
      let height = this.$refs.swipeItem[0].offsetHeight;
      document.getElementById("mySwipe").style.height = `${height}px`;
      this.mainloading = false;
    });
  },
  computed: {
    preMonth() {
      if (this.month == 1) {
        return {
          year: this.year - 1,
          month: 12,
        };
      } else {
        return {
          year: this.year,
          month: String(this.month - 1).padStart(2, "0"),
        };
      }
    },
    indexMonth() {
      return {
        year: this.year,
        month: String(this.month).padStart(2, "0"),
      };
    },
    nextMonth() {
      if (this.month == 12) {
        return {
          year: +this.year + 1,
          month: "01",
        };
      } else {
        return {
          year: this.year,
          month: String(+this.month + 1).padStart(2, "0"),
        };
      }
    },
    checkItemClock() {
      let list = this.checkItem.list || [];
      let onWork = list.find((item) => {
        return 0 == item.clockType;
      });
      let offWorkArr = list.filter((item) => {
        return 1 == item.clockType;
      });
      let offWork;
      let length = offWorkArr.length;
      if (length) {
        offWork = offWorkArr[length - 1];
      } else {
        offWork = null;
      }
      onWork = onWork || {};
      offWork = offWork || {};

      return { onWork, offWork };
    },
  },
  methods: {
    isChecked(item) {
      let arr = item.date.split("-");
      let year = arr[0],
        month = arr[1],
        day = arr[2];
      if (year == this.year && month == this.month && day == this.day) {
        return true;
      } else {
        return false;
      }
    },
    async getClockByDay() {
      for (let i = 0; i < 3; i++) {
        let postData;
        if (0 == i) {
          postData = this.preMonth;
        } else if (1 == i) {
          postData = this.indexMonth;
        } else {
          postData = this.nextMonth;
        }
        await getCalnndars(postData).then((res) => {
          if (200 == res.code) {
            this.calnndarList[i] = res.data;
          }
        });
      }
      let first = this.calnndarList.splice(0, 1);
      this.calnndarList = this.calnndarList.concat(first);

      let list = this.calnndarList[0];
      for (let i in list) {
        let arr = list[i].date.split("-");
        if (arr[1] == this.month && arr[2] == this.day) {
          this.checkItem = list[i];
        }
      }

      this.loading = false;
    },

    async swipeRoll(index, pre) {
      if (this.loading) {
        return;
      }
      if (1 == index) {
        this.month++;
        if (this.month > 12) {
          this.month = 1;
          this.year++;
        }
      } else {
        this.month--;
        if (this.month < 1) {
          this.month = 12;
          this.year--;
        }
      }
      this.prePage = index;
      this.loading = true;

      await this.getClockByDay();
      this.swipeKey++;
      this.loading = false;
      this.$nextTick(() => {
        let height = this.$refs.swipeItem[0].offsetHeight;
        document.getElementById("mySwipe").style.height = `${height}px`;
        let todayArr = this.today.split("-");
        if (this.month == todayArr[1]) {
          this.day = todayArr[2];
        } else {
          this.day = "01";
        }
        let list = this.calnndarList[0];
        for (let i in list) {
          let arr = list[i].date.split("-");
          if (arr[1] == this.month && arr[2] == this.day) {
            this.checkItem = list[i];
          }
        }
      });
    },
    handleCheck(item) {
      let arr = item.date.split("-");
      this.year = arr[0];
      this.month = arr[1];
      this.day = arr[2];

      this.checkItem = item;
    },
    backToday() {
      let arr = this.today.split("-");
      this.year = arr[0];
      this.month = arr[1];
      this.day = arr[2];
    },

    async getTime() {
      await getServiceTime().then((res) => {
        this.today = res.data.substring(0, 10);
        let arr = this.today.split("-");
        this.year = arr[0];
        this.month = arr[1];
        this.day = arr[2];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.by-day {
  position: relative;
  .loading-box {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: #fff;
  }
  .search {
    height: 1.12rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      height: 0.5rem;
      .split {
        height: 100%;
        margin: 0 0.12rem;
        background: #d9dcdc;
        width: 0.02rem;
      }
    }
  }
  .swipe-box {
    overflow: hidden;
    .calendar {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      align-items: center;
      .day-of-week {
        font-size: 0.2rem;
        color: #999;
      }
      .day-of-week {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0.16rem;
      }
      .date-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0.58rem;

        .date {
          font-size: 0.24rem;
          height: 0.6rem;
          width: 0.6rem;
          border-radius: 0.3rem;
          margin-bottom: 0.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .point {
          width: 0.1rem;
          height: 0.1rem;
          border-radius: 0.05rem;
          // background: #0094ee;
        }
      }
      .date-item.today {
        .date {
          background: #e2f3fe;
          color: #188fe0;
        }
      }
      .date-item.checked {
        .date {
          background: #0094ee;
          color: #fff;
        }
      }
      .date-item.restDay {
        color: #999;
      }
      .date-item.notMonthType {
        color: #999;
      }
      .date-item.abnormalClock {
        .point {
          background: #ff9700;
        }
      }
      .normalClock {
        .point {
          background: #0094ee;
        }
      }
    }
  }

  .split {
    height: 1px;
    background: #f0f0f0;
    text-align: center;
    .icon {
      color: #999;
      display: inline-block;
      position: relative;
      top: -0.25rem;
      font-size: 0.5rem;
      background: #fff;
      padding: 0 0.3rem;
      font-weight: lighter;
    }
  }
  .bottom {
    padding: 0.4rem 0.3rem;
    .row {
      font-size: 0.24rem;
      color: #999;
      margin-bottom: 0.5rem;
    }
    .clock-in-ul {
      .clock-in-li {
        display: flex;
        .left {
          width: 0.1rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-right: 0.3rem;
          .point {
            width: 0.1rem;
            height: 0.1rem;
            border-radius: 0.05rem;
            background: #999;
            margin-top: 0.15rem;
            margin-bottom: 0.15rem;
          }
          .line {
            width: 1px;
            background: #999;
            height: 0.2rem;
            flex: 1;
          }
        }

        .right {
          min-height: 1.8rem;
          .row1 {
            font-size: 0.3rem;
            font-weight: bold;
            margin-bottom: 0.28rem;
            display: flex;
            .tag {
              border: 1px solid #ff976a;
              font-size: 0.16rem;
              border-radius: 0.1rem;
              color: #ff976a;
              font-weight: 300;
              padding: 0.06rem 0.16rem 0rem 0.16rem;
              margin-left: 0.2rem;
              display: inline-block;
            }
          }
          .row2 {
            font-size: 0.22rem;
            margin-bottom: 0.9rem;
            color: #999;
            .icon {
              margin-right: 0.2rem;
            }
          }
        }
      }
      .clock-in-li:last-of-type {
        .left {
          .line {
            display: none;
          }
        }
      }
    }
  }
}
</style>
