
<template>
  <div class="by-month">
    <div class="search">
      <div class="month-row1">
        <div class="left">
          <div class="year">{{ year }}</div>
          <div class="split"></div>
          <div class="month">{{ month }}月</div>
        </div>
        <div class="today" @click="backToday">回到本月</div>
      </div>
      <van-swipe-cell :key="swipeKey" @open="changeUl" ref="swipeCell">
        <template #left>
          <ul class="search-month">
            <li
              :class="{
                month: true,
                checked: month == item,
                'this-month': today.substring(5, 7) == item,
              }"
              @click="changeMonth(item)"
              v-for="item in chooseMonth[0]"
              :key="item"
            >
              <span class="month-text">{{ item }}月</span>
            </li>
          </ul>
        </template>
        <ul class="search-month">
          <li
            :class="{
              month: true,
              checked: month == item,
              'this-month': today.substring(5, 7) == item,
            }"
            @click="changeMonth(item)"
            v-for="item in chooseMonth[1]"
            :key="item"
          >
            <span class="month-text">{{ item }}月</span>
          </li>
        </ul>
        <template #right>
          <ul class="search-month">
            <li
              :class="{
                month: true,
                checked: month == item,
                'this-month': today.substring(5, 7) == item,
              }"
              @click="changeMonth(item)"
              v-for="item in chooseMonth[0]"
              :key="item"
            >
              <span class="month-text">{{ item }}月</span>
            </li>
          </ul>
        </template>
      </van-swipe-cell>
    </div>
    <ul class="statistics">
      <li class="statistics-li">
        <p class="num">{{ map.workDays || 0 }}</p>
        <p class="text">出勤天数</p>
      </li>

      <li class="statistics-li">
        <p class="num">{{ map.totalWorkHours || 0 }}</p>
        <p class="text">总工时</p>
      </li>

      <li class="statistics-li">
        <p class="num">{{ map.averageHours || 0 }}</p>
        <p class="text">平均工时</p>
      </li>
      <li class="statistics-li">
        <p class="num">{{ map.lateNum || 0 }}</p>
        <p class="text">迟到</p>
      </li>
      <li class="statistics-li">
        <p class="num">{{ map.earlyNum || 0 }}</p>
        <p class="text">早退</p>
      </li>
      <li class="statistics-li">
        <p class="num">{{ map.lackNum || 0 }}</p>
        <p class="text">缺卡</p>
      </li>

      <li class="statistics-li">
        <p class="num">{{ map.absenteeism || 0 }}</p>
        <p class="text">旷工</p>
      </li>
      <li class="statistics-li">
        <p class="num">{{ map.outBoderNum || 0 }}</p>
        <p class="text">外勤</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { statisticsByMonth, getServiceTime } from "@/api/clock/index.js";

export default {
  name: "byMonth",
  props: {},
  data() {
    return {
      year: "",
      month: "",
      map: {},
      today: "",
      chooseMonth: [
        [1, 2, 3, 4, 5, 6],
        [7, 8, 9, 10, 11, 12],
      ],
      swipeKey: 0,
    };
  },
  watch: {},
  async mounted() {
    await this.getTime();
    this.getStatistics();
  },
  computed: {
    chooseMonth3() {
      return [this.chooseMonth[0], this.chooseMonth[1], this.chooseMonth[1]];
    },
    monthMM() {
      let monthStr = this.month.toString();
      if (1 == monthStr.length) {
        return 0 + monthStr;
      } else {
        return monthStr;
      }
    },
  },
  methods: {
    getStatistics() {
      statisticsByMonth({
        date: `${this.year}-${this.monthMM}`,
      }).then((res) => {
        this.map = res.data;
      });
    },

    changeMonth(item) {
      this.month = item;
      this.getStatistics();
    },

    changeUl(obj) {
      if ("left" == obj.position) {
        if (7 == this.chooseMonth[0][0]) {
          this.year--;
        }
      }
      if ("right" == obj.position) {
        if (1 == this.chooseMonth[0][0]) {
          this.year++;
        }
      }
      setTimeout(() => {
        let arr = this.chooseMonth[0];
        this.chooseMonth[0] = this.chooseMonth[1];
        this.chooseMonth[1] = arr;
        this.swipeKey++;
      }, 500);
    },
    async getTime() {
      await getServiceTime().then((res) => {
        this.today = res.data.substring(0, 10);
        this.year = Number(res.data.substring(0, 4));
        this.month = Number(res.data.substring(5, 7));
      });
    },
    backToday(){
      this.year=Number(this.today.substring(0, 4))
      this.month = Number(this.today.substring(5, 7));
      this.getStatistics();
    }
  },
};
</script>

<style lang="scss" scoped>
.by-month {
  .search {
    justify-content: space-between;
    border-bottom: 1px solid #ececec;
    .month-row1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 1.12rem;
      .left {
        display: flex;
        align-items: center;
        height: 0.5rem;
        .split {
          height: 100%;
          margin: 0 0.12rem;
          background: #d9dcdc;
          width: 0.02rem;
        }
      }
    }
    .search-month {
      height: 1.18rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 6.3rem;
      .month {
        width: 0.6rem;
        height: 0.6rem;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .month-text {
          font-size: 0.24rem;
          height: 0.6rem;
          width: 0.6rem;
          border-radius: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .month.this-month {
        .month-text {
          background: #e2f3fe;
          color: #188fe0;
        }
      }
      .month.checked {
        .month-text {
          background: #0094ee;
          color: #fff;
        }
      }
    }
  }

  .statistics {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1.5rem;
    margin-top: 0.6rem;
    .statistics-li {
      display: flex;
      align-items: center;
      flex-direction: column;

      .num {
        font-size: 0.4rem;
        margin-bottom: 0.24rem;
      }
      .text {
        font-size: 0.24rem;
        color: #666;
      }
    }
  }
  .tip {
    font-size: 0.24rem;
    color: #999;
    margin-bottom: 0.4rem;
  }
}
</style>
