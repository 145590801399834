var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"by-day"},[(_vm.mainloading)?_c('div',{staticClass:"loading-box"},[_c('van-loading')],1):_vm._e(),_c('div',{staticClass:"search"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"year"},[_vm._v(_vm._s(_vm.year))]),_c('div',{staticClass:"split"}),_c('div',{staticClass:"month"},[_vm._v(_vm._s(_vm.month))]),_c('div',{staticClass:"month"},[_vm._v(".")]),_c('div',{staticClass:"month"},[_vm._v(_vm._s(_vm.day))])]),_c('div',{staticClass:"back-today",on:{"click":_vm.backToday}},[_vm._v("回到今天")])]),_c('div',{staticClass:"swipe-box",style:(`height:${_vm.expand ? 'auto' : '1.2rem'}`)},[_c('van-swipe',{key:_vm.swipeKey,ref:"swipe",staticClass:"my-swipe",attrs:{"show-indicators":false,"id":"mySwipe"},on:{"change":_vm.swipeRoll}},_vm._l((_vm.calnndarList),function(item,index){return _c('van-swipe-item',{key:index},[_c('ul',{ref:"swipeItem",refInFor:true,staticClass:"calendar"},[_c('li',{staticClass:"day-of-week"},[_vm._v("一")]),_c('li',{staticClass:"day-of-week"},[_vm._v("二")]),_c('li',{staticClass:"day-of-week"},[_vm._v("三")]),_c('li',{staticClass:"day-of-week"},[_vm._v("四")]),_c('li',{staticClass:"day-of-week"},[_vm._v("五")]),_c('li',{staticClass:"day-of-week"},[_vm._v("六")]),_c('li',{staticClass:"day-of-week"},[_vm._v("日")]),_vm._l((item),function(subItem,subIndex){return _c('li',{key:'day' + index + subIndex,class:{
              'date-item': true,
              checked: _vm.isChecked(subItem), //选择
              today: _vm.today == subItem.date, //当天
              restDay: 1 == subItem.isRestDay, //非工作日
              notMonthType: 1 != subItem.monthType, //非当月
              abnormalClock: 0 == subItem.clockType, //异常打卡 或 外勤打卡
              normalClock: 1 == subItem.clockType, //正常打卡
            },on:{"click":function($event){return _vm.handleCheck(subItem)}}},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(subItem.day))]),_c('div',{staticClass:"point"})])})],2)])}),1)],1),_c('div',{staticClass:"split"},[(_vm.expand)?_c('van-icon',{staticClass:"icon",attrs:{"name":"arrow-up"},on:{"click":function($event){_vm.expand = !_vm.expand}}}):_vm._e(),(!_vm.expand)?_c('van-icon',{staticClass:"icon",attrs:{"name":"arrow-down"},on:{"click":function($event){_vm.expand = !_vm.expand}}}):_vm._e()],1),_c('div',{staticClass:"bottom"},[_c('p',{staticClass:"row"},[_vm._v("出勤统计：工时"+_vm._s(_vm.checkItem.totalHours || 0)+"小时")]),(2 != _vm.checkItem.clockType)?_c('ul',{staticClass:"clock-in-ul"},[_c('li',{staticClass:"clock-in-li"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"row1"},[_vm._v(" 上班 "+_vm._s(_vm._f("formatTime")(_vm.checkItemClock.onWork.clockTime))+" "),(2 == _vm.checkItemClock.onWork.clockStatus)?_c('span',{staticClass:"tag"},[_vm._v("迟到")]):_vm._e(),(!_vm.checkItemClock.onWork.clockStatus)?_c('span',{staticClass:"tag"},[_vm._v("缺卡")]):_vm._e(),(4 == _vm.checkItemClock.onWork.clockScope)?_c('span',{staticClass:"tag"},[_vm._v("外勤")]):_vm._e()]),(_vm.checkItemClock.onWork.address)?_c('div',{staticClass:"row2"},[_c('van-icon',{staticClass:"icon",attrs:{"name":"location-o"}}),_vm._v(_vm._s(_vm.checkItemClock.onWork.address)+" ")],1):_vm._e()])]),(
          !(_vm.today == this.checkItem.date && !_vm.checkItemClock.offWork.clockTime)
        )?_c('li',{staticClass:"clock-in-li"},[_vm._m(1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"row1"},[_vm._v(" 下班 "+_vm._s(_vm._f("formatTime")(_vm.checkItemClock.offWork.clockTime))+" "),(3 == _vm.checkItemClock.offWork.clockStatus)?_c('span',{staticClass:"tag"},[_vm._v("早退")]):_vm._e(),(!_vm.checkItemClock.offWork.clockStatus)?_c('span',{staticClass:"tag"},[_vm._v("缺卡")]):_vm._e(),(4 == _vm.checkItemClock.offWork.clockScope)?_c('span',{staticClass:"tag"},[_vm._v("外勤")]):_vm._e()]),(_vm.checkItemClock.offWork.address)?_c('div',{staticClass:"row2"},[_c('van-icon',{staticClass:"icon",attrs:{"name":"location-o"}}),_vm._v(_vm._s(_vm.checkItemClock.offWork.address)+" ")],1):_vm._e()])]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"point"}),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"point"}),_c('div',{staticClass:"line"})])
}]

export { render, staticRenderFns }