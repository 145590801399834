<template>
  <div class="statistics-container">
    <div class="company">{{ userInfo.rootDepartmentName }}</div>
    <div class="card">
      <div class="row1">
        <img :src="userInfo.avatarUrl || defaultAvatar" alt="" class="avatar" />
        <div class="middle">
          <div class="name">{{ userInfo.userName }}</div>
          <div class="department">
            <span class="departmentName">{{ userInfo.departmentName }}</span>
            <span class="go-clock-role" @click="goClockRole">(查看规则)</span>
          </div>
        </div>
        <div class="right">
          <div
            :class="{ tab: true, checked: 0 == searchBy }"
            @click="tabChange(0)"
          >
            日
          </div>
          <div class="split"></div>
          <div
            :class="{ tab: true, checked: 1 == searchBy }"
            @click="tabChange(1)"
          >
            月
          </div>
        </div>
      </div>
      <byDay v-if="0 == searchBy" />
      <byMonth v-else />
    </div>
  </div>
</template>

<script>
import { getMyStatffInfo } from "@/api/staff/index.js";
import byDay from "./components/byDay.vue";
import byMonth from "./components/byMonth.vue";
import defaultAvatar from "@/assets/images/home/header.png";

export default {
  name: "statistics",
  props: {},
  computed: {},
  components: { byDay, byMonth },
  data() {
    return {
      calnndarList: [],
      searchBy: 0, // 0-日期 1-年
      userInfo: {},
      defaultAvatar,
    };
  },
  watch: {},
  mounted() {
    this.getStaffInfo();
  },
  computed: {
    preMonth() {
      if (this.month == 1) {
        return {
          year: this.year - 1,
          month: 12,
        };
      } else {
        return {
          year: this.year,
          month: this.month - 1,
        };
      }
    },
    indexMonth() {
      return {
        year: this.year,
        month: this.month,
      };
    },
    nextMonth() {
      if (this.month == 12) {
        return {
          year: this.year + 1,
          month: 1,
        };
      } else {
        return {
          year: this.year,
          month: this.month + 1,
        };
      }
    },
  },
  methods: {
    isChecked(item) {
      let arr = item.date.split("-");
      let year = arr[0],
        month = arr[1],
        day = arr[2];
      if (year == this.year && month == this.month && day == this.day) {
        return true;
      } else {
        return false;
      }
    },
    tabChange(index) {
      this.searchBy = index;
    },
    getStaffInfo() {
      getMyStatffInfo().then((res) => {
        this.userInfo = res.data;
      });
    },
    goClockRole() {
      this.$router.push({
        path: "/clockRole",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #f3f6f6;
  padding-bottom: 60px;
  .company {
    background: #fff;
    height: 0.9rem;
    font-size: 0.38rem;
    font-weight: bold;
    line-height: 0.9rem;
    padding: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card {
    background: #fff;
    width: 6.9rem;
    padding: 0.3rem 0.3rem 0 0.3rem;
    border-radius: 0.12rem;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    .row1 {
      height: 1.41rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ececec;
      .avatar {
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.18rem;
        border-radius: 0.08rem;
      }
      .middle {
        flex: 1;
        width: 0.5rem;
        .name {
          font-size: 0.3rem;
          margin-bottom: 0.12rem;
        }
        .department {
          font-size: 0.26rem;
          color: #666;
          white-space: nowrap;
          display: flex;

          .departmentName {
            overflow: hidden;
            text-overflow: ellipsis;
            flex-shrink: 1;
          }
          .go-clock-role {
            color: #0094ee;
          }
        }
      }
      .right {
        background: #ecefef;
        border-radius: 0.08rem;
        height: 0.57rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.05rem;
        margin-left: 0.3rem;
        .tab {
          height: 0.48rem;
          width: 0.7rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .tab.checked {
          background: #fff;
        }
        .split {
          background: #d9dcdc;
          height: 0.24rem;
          width: 0.02rem;
        }
      }
    }
  }
}
</style>
